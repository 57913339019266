import Splide from '@splidejs/splide';

export class ProductSlider extends HTMLElement {
  constructor() {
    super();
    this.slider = false;
    this.settings = {
      type: 'loop',
      perPage: 1,
      perMove: 1,
      mediaQuery: 'min',
      pagination: false,
      easingFunc: 'cubic-bezier(0.25, 1, 0.5, 1)',
      arrowPath: 'M31 20.5 10.85 40 8 37.242 25.3 20.5 8 3.758 10.85 1l17.3 16.742L31 20.5Z',
      classes: {
        arrows: 'splide__arrows ',
        arrow: 'splide__arrow splide__arrow--lg',
        prev: '!-left-5 splide__arrow--prev',
        next: '!-right-5 splide__arrow--next'
      },
      breakpoints: {
        768: {
          perPage: 2,
          gap: 8
        },
        990: {
          perPage: 4,
          gap: 10
        }
      }
    };
  }
  connectedCallback() {
    this.slider = new Splide(this, this.settings);
    this.slider.mount();
  }
}

customElements.define('product-slider', ProductSlider);
